/* eslint-disable no-return-assign */
import { findKey } from 'lodash';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import ModalContext from './context';

import PlansModal, { checkPlans } from 'Components/Popups/ActivatePlan';
import RedirectModal, { checkRedirect } from 'Components/Popups/Redirect';
import RegisterModal, { checkRegister } from 'Components/Popups/Register';
import SuccessModal from 'Components/Popups/Success';
import TermsModal, { checkTerms } from 'Components/Popups/Terms';

const intialContext = {};

const reducer = (state, action) => {
	switch (action.type) {
		case 'OPEN_MODAL': {
			const { name, props } = action.payload;
			const keys = Object.keys(state);
			const newState = keys.reduce((acc, v) => ({ ...acc, [v]: { ...state[v], open: false } }), {});

			newState[name] = { open: true, props };
			return newState;
		}
		case 'CLOSE_MODAL': {
			const { name } = action.payload;
			return { ...state, [name]: { open: false } };
		}
		case 'DISABLE_MODAL': {
			const { name } = action.payload;
			return { ...state, [name]: { open: false, disabled: true } };
		}
		case 'RESET': {
			return intialContext;
		}
		default:
			return state;
	}
};

const ModalContextProvider = ({ children }) => {
	const [data, dispatch] = useReducer(reducer, intialContext);
	const modals = {
		register: {
			component: RegisterModal,
			check: checkRegister,
		},
		plans: {
			component: PlansModal,
			check: checkPlans,
		},
		success: {
			component: SuccessModal,
		},
		terms: {
			component: TermsModal,
			check: checkTerms,
		},
		redirect: {
			component: RedirectModal,
			check: checkRedirect,
		},
	};
	const getOpenModal = () => findKey(data, { open: true });

	const openModal = (name, props = {}) => {
		if (!modals[name]) return;
		if (getOpenModal()) return;
		if (data[name] && data[name].disabled) return;

		const close = () => closeModal(name);

		dispatch({ type: 'OPEN_MODAL', payload: { name, props: { ...props, openModal, close } } });
	};

	const closeModal = name => {
		if (!modals[name]) return;
		dispatch({ type: 'CLOSE_MODAL', payload: { name } });
	};

	// Return true in the check function if the modal will be opened
	const checkModal = async (name, params = {}) => {
		if (!modals[name]) return false;
		if (data[name] && (data[name].open || data[name].disabled)) return false;

		if (typeof modals[name].check === 'function') {
			const needOpen = await modals[name].check(params);
			if (!needOpen) return false;
		}

		openModal(name, params);
		return true;
	};

	const disableModal = name => {
		dispatch({ type: 'DISABLE_MODAL', payload: { name } });
	};

	return (
		<ModalContext.Provider value={{ openModal, closeModal, checkModal, disableModal, data }}>
			<>
				{children}
				{getOpenModal() && React.createElement(modals[getOpenModal()].component, data[getOpenModal()].props)}
			</>
		</ModalContext.Provider>
	);
};

ModalContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ModalContextProvider;
